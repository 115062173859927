<template>
  <div>    
      <!--BANNER -->
        <Header>
          <!--<img title="Sistema para loteamentos - scae" alt="sistema completo para gestão de loteamento" desc slot="logo-dark" src="../../assets/images/logo/logo-negativa.png" class="logo-light"/>-->  
          <img title="sistema completo para loteamento - scae" alt="sistema completo para gestão de loteamento" slot="logo-dark" src="../../assets/images/logo/logoscae.png" class="logo-dark"/>
        </Header>
  
        <div class="rn-slider-area" id="home">
          <div class="slide slide-style-2">
            <Banner @scroll-to-form="scrollToForm" ref="banner">
              <p slot="description" class="pt-6 description">Um passo para seu negócio, um salto para seu sucesso!</p>
            </Banner>  
          </div>  
        </div>
      <!--BANNER -->  
        
      

      <!--SERVIÇOS -->
       <div class="rn-featured-service-area pt--90 pb--120 bg_color--5" id="serviços">
        <v-row>
          <v-col lg="12" md="12" cols="12" class="mt--30">
              <div class="section-title-sevices aswer-fade">
                <p>
                  <span class="quotation-marks">"</span>
                  Conheça as vantagens de automatizar a gestão da carteira de recebíveis do seu loteamento através do Sistema <span style="color: #f6631e;">SCAE.</span>
                  <span class="quotation-marks">"</span>
                </p>
              </div>
            </v-col>
        </v-row>
        <v-container>
          <v-row>
           
            <v-col lg="12" md="12" cols="12">
              <ServiceFive ref="module" />
            </v-col>
          </v-row>
        </v-container>
       </div>
      <!--SERVIÇOS -->

      <!-- Questions SECTION -->
      
        <Questions id="questions"></Questions>   
        <!-- QUESTIONS SECTION -->
          
      <!--INFO SISTEMA -->
      <div class="main-container-about">
            <div class="container-button-service">
              <a target="_blank" class="rn-button-style--2" title="Sistema para a gestão de loteamento"  href="https://api.whatsapp.com/send?phone=5519999600500&text=Olá! Vim pelo site e quero mais informações.">Contrate Agora!<i class="fa fa-arrow-right arrow-in-button" style="white"></i></a>
            </div>
        <div class="rn-content-box-area rn-content-box-style--1 bg_color--1 pt--50" style="background-color: #FAFAFA">
          <v-row justify="center" align="center">
            <v-col lg="12" md="12" cols="12" class="display fadein-right">
              <div class="designer-thumbnail img">
                <v-carousel height="500"  hide-delimiter-background cycle :show-arrows="false" title="Sistema para gestão de aréa do cliente para loteamentos - scae" alt="Sistema para gestão de aréa do cliente para loteamentos - scae">
                  <v-carousel-item v-for="(item, i) in items" :key="i" title="Sistema para loteamentos - scae" alt="sistema completo para gestão de loteamento">
                    <div class="custom-carousel-item">
                      <img class="carousel-image" :src="item.src">
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </div>
            </v-col>

            <v-col md="12" lg="12" cols="12" class="mt_lg--50 mt_md--30">
              <div class="content fadein-left">
                <p class="subtitle"></p>
                <h2 class="fontWeight500">Contratos</h2>
                <p>
                  Ao receber a proposta do seu corretor e cadastrar no sistema você inicia todo o controle da operação, desde a elaboração da promessa de compra e venda, gerar a entrada com os valores para a loteadora ou para a vendedora,
                  gerar parcelas do financiamento, aditar contratos, envio de boletos e e-mails  automáticos.
                </p>
              </div>
            </v-col>
          </v-row>
        </div> 

        <div class="rn-content-box-area rn-content-box-style--1 bg_color--1" style="background-color: #FAFAFA">
          <v-row justify="center" align="center">
            

            <v-col lg="12" md="12" cols="12" class="display fadein-left">
              <div class="designer-thumbnail img">
                <v-carousel height="500" hide-delimiter-background cycle :show-arrows="false" title="Sistema para gestão de aréa do cliente para loteamentos - scae" alt="Sistema para gestão de aréa do cliente para loteamentos - scae">
                  <v-carousel-item v-for="(item, i) in itemsCobranca" :key="i" title="Sistema para loteamentos - scae" alt="sistema completo para gestão de loteamento">
                    <div class="custom-carousel-item">
                      <img class="carousel-image" :src="item.src">
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </div>
            </v-col>
            <v-col md="12" lg="12" cols="12" class="mt_lg--50 mt_md--30">
              <div class="content fadein-right">
                <p class="subtitle"></p>
                <h2 class="fontWeight500">Cobranças automatizadas</h2>
                <p>
                  Controle com mais precisão o seu fluxo de caixa. Nós emitimos as cobranças, enviamos por e-mail e Whatsapp de forma automática. Você ainda tem acesso a régua de cobrança com todos os status dos pagamentos e inadimplência por tempo de atraso em tempo real.
                </p>
              </div>
            </v-col>
          </v-row>
        </div> 

        <div class="rn-content-box-area rn-content-box-style--1 bg_color--1" style="background-color: #FAFAFA">
          <v-row justify="center" align="center">
            <v-col lg="12" md="12" cols="12" class="display fadein-right">
              <div class="designer-thumbnail img">
                <v-carousel height="500" hide-delimiter-background cycle :show-arrows="false" title="Sistema para gestão de aréa do cliente para loteamentos - scae" alt="Sistema para gestão de aréa do cliente para loteamentos - scae">
                  <v-carousel-item v-for="(item, i) in itemsComprador" :key="i" title="Sistema para loteamentos - scae" alt="sistema completo para gestão de loteamento">
                    <div class="custom-carousel-item">
                      <img class="carousel-image" :src="item.src">
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </div>
            </v-col>

            <v-col md="12" lg="12" cols="12" class="mt_lg--50 mt_md--30">
              <div class="content fadein-left">
                <p class="subtitle"></p>
                <h2 class="fontWeight500">Página do comprador do Lote</h2>
                <p>
                  Seu cliente pode emitir a 2a via de boleto, gerar boleto de quitação e antecipação de parcelas, além de desfrutar do total controle de seus pagamentos e emitir o histórico anual para declaração do IRPF. 
                  Com a Scae você disponibiliza uma página para o comprador com todas as informações que ele precisa. Com isso, reduza trabalhos operacionais do seu time.
                </p>
              </div>
            </v-col>
          </v-row>
        </div> 

        <div class="rn-content-box-area rn-content-box-style--1 bg_color--1" style="background-color: #FAFAFA">
          <v-row justify="center" align="center">
            <v-col lg="12" md="12" cols="12" class="display fadein-left">
              <div class="designer-thumbnail img">
                <v-carousel height="500" hide-delimiter-background cycle :show-arrows="false" title="Sistema para gestão de aréa do cliente para loteamentos - scae" alt="Sistema para gestão de aréa do cliente para loteamentos - scae">
                  <v-carousel-item v-for="(item, i) in itemsKpi" :key="i" title="Sistema para loteamentos - scae" alt="sistema completo para gestão de loteamento">
                    <div class="custom-carousel-item">
                      <img class="carousel-image" :src="item.src">
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </div>
            </v-col>
            <v-col md="12" lg="12" cols="12" class="mt_lg--50 mt_md--30">
              <div class="content fadein-right">
                <p class="subtitle"></p>
                <h2 class="fontWeight500">Dashboards e KPIS</h2>
                <p>
                Com Dashboards e KPIS da Scae é possível visualizar dados de faturamento mensal, vendas por corretor, inadimplência mensal, acordos realizados e muito mais, tudo em formato gráfico de fácil e rápida leitura da situação do seu loteamento ou da sua empresa. Com os resultados de desempenho fica fácil traçar e acompanhar às metas da empresa, incorporando os dados financeiros e operacionais ao processo de negócio.
                </p>
              </div>
            </v-col>

            
          </v-row>
        </div> 
        <div class="rn-content-box-area rn-content-box-style--1 bg_color--1" style="background-color: #FAFAFA">
          <v-row justify="center" align="center">
            <v-col lg="12" md="12" cols="12" class="display fadein-left">
              <div class="designer-thumbnail img">
                <v-carousel height="500" hide-delimiter-background cycle :show-arrows="false" title="Sistema para gestão de aréa do cliente para loteamentos - scae" alt="Sistema para gestão de aréa do cliente para loteamentos - scae">
                  <v-carousel-item v-for="(item, i) in itemsCRM" :key="i" title="Sistema para loteamentos - scae" alt="sistema completo para gestão de loteamento">
                    <div class="custom-carousel-item">
                      <img class="carousel-image" :src="item.src">
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </div>
            </v-col>
            <v-col md="12" lg="12" cols="12" class="mt_lg--50 mt_md--30">
              <div class="content fadein-right">
                <p class="subtitle"></p>
                <h2 class="fontWeight500">Domine suas Vendas de Loteamento com o CRM SCAE</h2>
                <p>
                  Centralize todas as vendas dos seus lotes em um único lugar. Ofereça acesso completo a seus corretores e imobiliárias parceiras. Crie um funil de vendas eficiente e gerencie seu time com facilidade. Transforme seu processo de vendas com nosso CRM especializado e veja suas vendas de loteamento dispararem.
                </p>
              </div>
            </v-col>
          </v-row>
        </div> 
      </div>
        
        <!--INFO SISTEMA -->

        <!-- Formulário -->
          <Formulario id="formulario" ref="formulario"></Formulario>
        <!-- FIM Formulário -->

        <!-- Avaliações -->
          <Opnions id="opnions"></Opnions>
            
        <!-- FIM AVALIAÇÕES -->

        <!-- SOBRE  -->
        <div class="about-area rn-section-gap bg_color--1" id="sobre" >
          <About>
            <img slot="thum-img" class="w-100" src="../../assets/images/about/about-3.jpg" title="Sistema para loteamentos - scae" alt="Sistema para gestão de cobrança de loteamento - scae"/>
          </About>
        </div>  
      <!-- SOBRE -->

      <!-- CLIENTES -->
    
       <!-- CLIENTES --> 

      <!-- CONTATO -->
        <div class="about-area rn-section-gap bg_color--1"  style="background-color: #fafafa;" id="contato">
          <Contact>
            <img slot="thum-img" class="w-100" src="../../assets/images/about/about-3.jpg" title="Sistema para loteamento - scae" alt="Otimize a gestão do seu loteamento - scae"/>
          </Contact>
        </div>
      <!-- CONTATO -->
  
      <!-- RODAPÉ  -->
        <Footer />
      <!-- RODAPÉ  -->

      <!-- <div class="pt--80 mb--60">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="section-title text-center mb--20 mb_sm--0 mb_md--0">
                <h2 class="heading-title">Planos</h2>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div class="main" style="overflow-x:auto;">
        <table class="price-table">
          <tbody>
              <tr>
                <td class="price-blank"></td>
                <td class="price-blank"></td>
                <td class="price-blank"></td>
                <td class="price-table-popular">Mais popular</td>
              </tr>
              <tr class="price-table-head">
                <td></td>
                <td>Plano Standard </td>
                <td>Plano Business </td>
                <td>Plano Corporate</td>
              </tr>
              <tr>
                <td></td>
                  <td class="price">
                    <svg  xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 128 128" title="Sistema para loteamentos - scae" alt="Sistema para loteamentos - scae">
                      <defs/>
                      <path d="M76.1 91.4V71.7a1 1 0 00-.2-.6l-5.7-6.9V15.4a1.1 1.1 0 00-.1-.4.5.5 0 000-.1l-5.8-10a1 1 0 00-1.7 0l-5.9 10a.5.5 0 000 .1 1.2 1.2 0 00-.1.4v48.8l-5.7 6.9a1 1 0 00-.2.6v19.7a1 1 0 001 1h10.6V95a1 1 0 102 0v-2.7h10.8a1 1 0 001-1zm-17.6-75h9.7v4h-9.7zm4.8-9l4.1 7h-8.1zM52.6 72l4-4.8v23h-4zm11.7 18.3V56.7a1 1 0 10-2 0v33.7h-3.8v-68h9.7v68zm9.8 0h-4v-23l4 4.7z"/>
                      <path d="M59 94.7a1 1 0 00-1 1v10.9a4.2 4.2 0 00-3.3 2.7 1 1 0 00.6 1.3 1 1 0 00.4 0 1 1 0 001-.6 2.2 2.2 0 012.8-1.4 1 1 0 10.7-1.9H60v-11a1 1 0 00-1-1zM68.7 101.2v-5.5a1 1 0 00-2 0v5.5a1 1 0 102 0zM60.2 112.8a1.4 1.4 0 011.3 1 1 1 0 001.9.3 2.5 2.5 0 014.7 1.4 1 1 0 002 0 4.6 4.6 0 00-7.4-3.6 3.4 3.4 0 00-2.5-1.1 1 1 0 000 2zM73.2 109.7a1 1 0 002 0 6.1 6.1 0 00-6.1-6.1 1 1 0 000 2 4.1 4.1 0 014.1 4zM105.2 109a9.8 9.8 0 00-19-3 6.4 6.4 0 00-2.8-.7 6.5 6.5 0 00-6.5 6.5 1 1 0 002 0 4.5 4.5 0 014.5-4.5 4.4 4.4 0 012.8 1 1 1 0 001.6-.6 7.8 7.8 0 0115.4 1.4 1 1 0 002 0zM7.8 123a8 8 0 006.2-.1 1 1 0 00-.8-1.8 6 6 0 01-8-3 6 6 0 012.9-8 9.4 9.4 0 001.5.8 1 1 0 00.4.1 1 1 0 00.4-2 7.5 7.5 0 01-1-13.2 1 1 0 00.1-1.6A4.2 4.2 0 018.1 91a4.2 4.2 0 017.1-3 1 1 0 001 .2 1 1 0 00.6-.6 5.8 5.8 0 0111.3 1.1A7.4 7.4 0 0022 96a1 1 0 002 0 5.4 5.4 0 118.6 4.4 8.9 8.9 0 00-5.4 7 6.6 6.6 0 00-1 0 6.4 6.4 0 00-4.5 1.9 1 1 0 000 1.4 1 1 0 001.4 0 4.4 4.4 0 017.5 3.1 1 1 0 002 0 6.4 6.4 0 00-3.4-5.7 6.8 6.8 0 014.2-5.9 6.8 6.8 0 019.4 5.9 1 1 0 001.4.8 4.8 4.8 0 011.8-.3 5 5 0 015 5 1 1 0 002 0 7 7 0 00-8.4-6.9 8.9 8.9 0 00-8.6-7h-.2a7.4 7.4 0 00-5.7-11.1 7.8 7.8 0 00-14.6-2.9 6.2 6.2 0 00-3.2-.9 6.2 6.2 0 00-5 10 9.4 9.4 0 00-1 13.9 8.1 8.1 0 00-3.3 4A8 8 0 007.8 123zM91.8 119.8a1 1 0 002 0 6.4 6.4 0 00-11-4.5 1 1 0 000 1.4 1 1 0 001.4 0 4.4 4.4 0 013.1-1.3 4.4 4.4 0 014.5 4.4zM125.4 103.3a7.7 7.7 0 00-3.4-6.4 8.1 8.1 0 00.7-3.3A8.3 8.3 0 00107 90a5.8 5.8 0 00-4 10.6 1 1 0 101.1-1.7 3.8 3.8 0 013.2-6.8 1 1 0 001.2-.6 6.3 6.3 0 0112.2 2 6.2 6.2 0 01-1 3.3 1 1 0 000 .8 1 1 0 00.5.6 5.6 5.6 0 011 9.5 8.4 8.4 0 00-2.8-1.6 1 1 0 10-.6 1.8 6.7 6.7 0 01-.4 12.8 1 1 0 00-.7 1.2 1 1 0 001 .8 1.1 1.1 0 00.2 0A8.7 8.7 0 00124 112a8.7 8.7 0 00-1.3-2.8 7.6 7.6 0 002.7-6z"/>
                    </svg>
                      <br>
                      BRONZE
                   </td>
                    <td class="price">
                      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 128 128" title="Sistema para loteamentos - scae" alt="Sistema para loteamentos - scae">
                          <defs/>
                          <path d="M62.6 103.3a1 1 0 00-1.4 0L44 120.8a1 1 0 000 1.4 1 1 0 00.7.3 1 1 0 00.7-.3l17.3-17.3a1 1 0 000-1.5zM58.7 116.3a1 1 0 00.7-.3l7.2-7.2a1 1 0 00-1.4-1.5l-7.2 7.3a1 1 0 00.7 1.7zM55.2 117.4l-2.8 2.8a1 1 0 00.7 1.7 1 1 0 00.7-.3l2.8-2.8a1 1 0 000-1.4 1 1 0 00-1.4 0zM5.9 84.1a1 1 0 00.7.3 1 1 0 00.7-.3l17.4-17.3a1 1 0 10-1.5-1.4L6 82.7a1 1 0 000 1.4zM13.4 70l7.3-7.2a1 1 0 10-1.5-1.4L12 68.6a1 1 0 00.7 1.7 1 1 0 00.7-.3zM7.8 75.6l2.8-2.8a1 1 0 000-1.4 1 1 0 00-1.4 0l-2.8 2.8a1 1 0 000 1.4 1 1 0 00.7.3 1 1 0 00.7-.3zM68 33.6L27.1 40a1 1 0 00-.5.3L20 46.8a1 1 0 000 1.5l16.6 16.6-4.7 4.7a1 1 0 000 1.5l11.5 11.4-4.6 4.6a1 1 0 00.7 1.7 1 1 0 00.7-.3l4.6-4.6L57 96a1 1 0 001.4 0l4.7-4.7 16.7 16.6a1 1 0 001.4 0l6-6 .5-.5a1 1 0 00.3-.6L94.4 60l20.3-20.3a22.8 22.8 0 006-10.3.6.6 0 000-.2 22 22 0 00.5-3.4l1.1-11.3a8.1 8.1 0 00-8.8-8.8l-11.3 1a22.7 22.7 0 00-3.4.6h-.1a22.8 22.8 0 00-10.4 6zm-23.9 24L28.4 41.8 65.6 36zm-22-10l4.6-4.6 16 16-4.6 4.5zm58.4 58.2l-16-16 4.5-4.5 16 16zm5.6-6.3L70.5 84 92 62.4zm16.3-90.8l11.3-1.1a6.1 6.1 0 016.7 6.7l-1.1 11.3-.2 1.3-18-18 1.3-.2zm-3.6.7l19.8 19.8a21 21 0 01-5.3 9L57.7 94 46.3 82.5 75 53.7a1 1 0 000-1.4 1 1 0 00-1.5 0L45 81.1 34 70.3l10.7-10.6 45-45a21 21 0 019-5.3zM10.9 120.4a3.3 3.3 0 001.4-.3l19.5-9.1A11.2 11.2 0 1017 96.2l-9.1 19.5a3.3 3.3 0 003 4.7zm-1.2-3.9l9-19.5a9.1 9.1 0 016.8-5.2 10.2 10.2 0 011.7-.1 9.2 9.2 0 019 10.8 9.1 9.1 0 01-5.2 6.7l-19.5 9a1.3 1.3 0 01-1.8-1.7z"/>
                          <path d="M96.2 42.3a10.5 10.5 0 10-7.4-3 10.4 10.4 0 007.4 3zm-6-16.5a8.5 8.5 0 110 12 8.4 8.4 0 010-12z"/>
                      </svg>
                      <br>PRATA
                    </td>
                    <td class="price">
                      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 128 128" title="Sistema para loteamentos - scae" alt="Sistema para loteamentos - scae">
                          <defs/>
                          <path d="M38.6 53.2a14.6 14.6 0 0010.6-24.6 1 1 0 00-.7-.3 1.1 1.1 0 00-.7.3l-8.9 8.9-1.7-1.7a1 1 0 00-1.4 1.4l1.7 1.7-9 8.9a1 1 0 000 1.4 14.5 14.5 0 0010.1 4zm9.9-22.4a12.6 12.6 0 01-17.7 17.7z"/>
                          <path d="M2.7 102.7a1 1 0 00.3.7L24.5 125a1 1 0 00.7.3 1 1 0 00.7-.3l12.4-12.3a.8.8 0 00.4-.5l12.1-12a1 1 0 00.3-.2.9.9 0 00.2-.3l12.3-12.3a1 1 0 000-1.4l-8.4-8.4 1.5-1.5 11.8 11.9 3.7 6.4.1.1a1 1 0 00.1.2l5.1 5.1a1 1 0 00.7.3 1 1 0 00.7-.3L99.7 79a1 1 0 000-1.4l-5-5.1a1 1 0 00-.2-.1h-.1l-6.5-3.8-11.8-11.9 1.4-1.4 8.4 8.3a1 1 0 00.7.3 1 1 0 00.7-.3L125 26a1 1 0 000-1.4L103.5 3a1 1 0 00-1.4 0L89.7 15.4a.8.8 0 00-.4.5L77.2 28a1 1 0 00-.3.2.9.9 0 00-.2.3L64.4 40.8a1 1 0 000 1.4l8.4 8.4-1.5 1.4-11.4-11.4a1 1 0 00-1.4 0l-18 17.9a1 1 0 000 1.4L52 71.3l-1.4 1.4-8.4-8.3a1 1 0 00-1.4 0L3 102a1 1 0 00-.3.7zM29 79l9.3 9.3L27 99.5l-9.4-9.3zm10.7 10.8l9.3 9.3-11.1 11.1-9.3-9.3zm38.7 7.8L74.6 94 94 74.6l3.7 3.7zm-4.9-5.3l-2.8-5 16.8-16.7 5 2.8zm-4-6.5L67 83.6 78.7 72a1 1 0 000-1.4 1 1 0 00-1.4 0L65.7 82.2 46.3 62.8l16.5-16.5 19.4 19.4-1.7 1.7a1 1 0 101.4 1.4l1.7-1.7 2.2 2.2zm20.8-68l9.3 9.3-11 11.1L79 29zm10.7 10.8l9.4 9.3L99.2 49l-9.4-9.3zm1.9-23.4l20 20-11.1 11.2-9.3-9.3 10-10a1 1 0 000-1.4 1 1 0 00-1.5 0l-10 10-9.3-9.3zM77.7 30.3l9.3 9.3-9.6 9.7a1 1 0 000 1.4 1 1 0 001.4 0l9.6-9.7 9.3 9.4-11 11.1-20.2-20zM74.2 52l2 2-1.5 1.4-2-2zm-15-9.2l2.2 2.2L45 61.4l-2.2-2.2zm-5.8 30l1.9 2-1.5 1.4-2-2zm-12-6.2l20 20-11 11.2-9.4-9.3 9.6-9.7a1 1 0 00-1.4-1.4L39.6 87l-9.3-9.3zm-25 25l9.2 9.4-10 10a1 1 0 000 1.4 1 1 0 001.5 0l10-10 9.3 9.3-11.2 11.2-20-20zM43.6 28.2a1 1 0 00-.3-1.4 11.9 11.9 0 00-16.5 16.5 1 1 0 00.9.5 1 1 0 00.5-.2 1 1 0 00.3-1.4 9.9 9.9 0 0113.7-13.7 1 1 0 001.4-.3z"/>
                          <path d="M38.6 32.3a1 1 0 10.9-1.8 6.7 6.7 0 00-9 9 1 1 0 00.9.5 1 1 0 00.9-1.4 4.7 4.7 0 016.3-6.3z"/>
                      </svg>
                    <br>OURO
                  </td>
                  
                </tr>
              <tr>
                <td> Quantidade de Lotes:</td>
                <td>Até 500</td>
                <td>Até 1000</td>
                <td>+ 1000</td>
              </tr>
              <tr>
                <td>Quantidade de usuários online</td>
                <td>10</td>
                <td>30</td>
                <td>Ilimitado</td>
              </tr>
              <tr>
                <td> Automação de envio de boletos por e-mail:</td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
              <tr>
                <td>  Automação de envio de boletos por Whatsapp:</td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
              <tr>
                <td>  Régua de cobrança</td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
              <tr>
                <td>  Módulo do financeiro</td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
              <tr>
                <td>  Módulo de gestão de contratos</td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
              <tr>
                <td>  Módulo de suprimentos</td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
              <tr>
                <td>  Módulo de gestão de obras</td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
              <tr>
                <td>  Módulo de gestão de serviços terceiros</td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
              <tr>
                <td>  Treinamento ao vivo</td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
              <tr>
                <td></td>
                <td class="price"><a target="_blank" href="https://api.whatsapp.com/send?phone=5521995343298&text=Olá! Vim pelo site e quero mais informações.">Entre em Contato</a></td>
                <td class="price"><a target="_blank" href="https://api.whatsapp.com/send?phone=5521995343298&text=Olá! Vim pelo site e quero mais informações.">Entre em Contato</a></td>
                <td class="price"><a target="_blank" href="https://api.whatsapp.com/send?phone=5521995343298&text=Olá! Vim pelo site e quero mais informações.">Entre em Contato</a></td>
              </tr>
          </tbody>
        </table>
      </div>

      <div class="rn-content-box-area rn-content-box-style--1 bg_color--1 pt--50  pb--50">
        <v-row class="row--0" align="center">
          <v-col md="12" lg="6" cols="12" class="mt_lg--50 mt_md--30">
            <div class="content">
              <p class="subtitle"></p>
              <h2 class="fontWeight500">Otimize sua Gestão</h2>
              <p>
                Nós somos uma proptech que utiliza tecnologia própria como combustão para que você possa alçar voos maiores. 
                Confira os nossos planos:
              </p>
              <a target="_blank"
                class="rn-button-style--2 mt--10 mt_md--30"
                href="https://api.whatsapp.com/send?phone=5521995343298&text=Olá! Vim pelo site e quero mais informações."
                ><span>Entre em Contato</span></a>
            </div>
          </v-col>

          <v-col lg="6" md="12" cols="12">
            <div class="thumbnail img">
              <img title="Sistema para loteamentos - scae" alt="Otimize a gestão do seu loteamento - scae" class="img" src="../../assets/images/servico/planos-img.jpg"/>
            </div>
          </v-col>
        </v-row>
      </div> -->
      <!-- PLANOS DOS SERVIÇOS GESTÃO DE LOTEAMENTOS -->


      
  </div>
</template>

<script>
import Header from "../../components/header/Header";
import Banner from "../../components/slider/Banner";
import CallToActionTwo from "../../components/callto-action/CallToActionTwo";
import Contador from "../../components/contador/Contador";
import Service from "../../components/service/Servico.vue";
import ServiceSoftware from "../../components/service/ServiceSoftware.vue";
import ServiceManagement from "../../components/service/Portfolio.vue";
import Contact from "../../components/about/About";
import Footer from "../../components/footer/Footer";
import About from "../../components/about/About2";
import TeamFour from "../../components/team/TeamFour.vue";
import ServiceFive from "../../components/service/ServiceFive.vue"
import Questions from "../../components/questions/questions.vue";
import Opnions from "../../components/opnions/opnions.vue";
import Formulario from "../../components/formulario/formulario.vue";

export default {
  components: {
    Header,Banner,CallToActionTwo,Service,ServiceSoftware,ServiceManagement,TeamFour,Contador,Footer,Contact,About, ServiceFive,Questions, Opnions,Formulario
  },
  methods: {
    scrollToForm() {
      this.$nextTick(() => {
        const element = this.$refs.formulario.$el;
        if (element) {
          const rect = element.getBoundingClientRect();
          const offset = 100;

          window.scrollTo({
            top: window.scrollY + rect.top - offset,
            behavior: 'smooth'
          });
        }
      });
    }
  },
  data() {
    return {
     items: [
        {
          src: require('../../assets/images/servico/contrato-img1.svg'),
        },
        {
          src: require('../../assets/images/servico/contrato-img1.png'),
        }
      ],

      itemsKpi: [
        {
          src: require('../../assets/images/servico/kpi-img.svg'),
        },
          {
          src: require('../../assets/images/servico/dashboard-novo.png'),
        }
      ],

       itemsCobranca: [
        {
          src: require('../../assets/images/servico/cobranca-img.svg'),
        },
          {
          src: require('../../assets/images/servico/regua.png'),
        }
      ],

      itemsComprador: [
        {
          src: require('../../assets/images/servico/comprador-img.svg'),
        },
          {
          src: require('../../assets/images/servico/cliente.png'),
        }
      ],   
      itemsCRM: [
        {
          src: require('../../assets/images/servico/crm-novo-2.png'),
        },
          {
          src: require('../../assets/images/servico/crm-novo.png'),
        }
      ],         
    };
    
  },mounted(){  
    ScrollReveal().reveal('.fadein', {delay:600,easing: 'ease-in'})
    ScrollReveal().reveal('.fadein-bottom', {delay:500,easing: 'ease-in',origin: 'bottom',distance: '60px'})
    ScrollReveal().reveal('.fadein-left', {delay:500,easing: 'ease-in',origin: 'left',distance: '60px'})
    ScrollReveal().reveal('.fadein-right', {delay:500,easing: 'ease-in',origin: 'right',distance: '60px'})
  }
};
</script>
<style scoped>
@media only screen and (max-width: 1265px) {

}

.main {
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
    font-family: "Open Sans";
    width: 100%;
    margin: 0 auto;
}

.price-table {
    width: 100%;
    border-collapse: collapse;
    border: 0 none;
}
.price-table tr:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.price-table tr td {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px 24px;
    font-size: 14px;
}
.price-table tr td:first-child {
    border-left: 0 none;
}
.price-table tr td:not(:first-child) {
    text-align: center;
}
.price-table tr:nth-child(even) {
    background-color: #FFFFFF;
}
.price-table tr:hover {
    background-color: #EEEEEE;
}
.price-table .fa-check {
    color: #19853a;
}
.price-table .fa-times {
   /* color: #e3d6d6;*/
   color: #ee5757;
}

/* Highlighted column */
.price-table tr:nth-child(2n) td:nth-child(4) {
    background-color: rgba(216, 214, 227, 0.25);
}
.price-table tr td:nth-child(4) {
    background-color: rgba(216, 214, 227, 0.15);
    padding: 8px 48px;
} 
.price-table tr.price-table-head td {
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat";
    text-transform: uppercase;
}
.price-table tr.price-table-head {
    background-color: #f6631e;
    color: #FFFFFF;
}
.price-table td.price {
    color: #383837;
    padding: 16px 24px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat";
}
.price-table td.price a {
    background-color: #f6631e;
    color: #FFFFFF;
    padding: 12px 32px;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat";
    text-transform: uppercase;
    display: inline-block;
    border-radius: 64px;
}
.price-table td.price-table-popular {
    font-family: "Montserrat";
    border-top: 3px solid #f6631e;
    color: #f6631e;
    text-transform: uppercase;
    font-size: 12px;
    padding: 12px 48px;
    font-weight: 700;
}
.price-table .price-blank {
    background-color: #fafafa;
    border: 0 none;
}

.price-table svg {
  width: 90px;
  fill: #f6631e;
}

.icon-color {
   color: #f6631e;
}


@media only screen and (max-width: 1360px) {
  .price-table td.price a {
    text-transform: uppercase;
    font-size: 9px;
    padding: 10px 10px;
    font-weight: 800;
  }
  .price-table tr td {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px 24px;
    font-size: 10px;
  } 
}

@media only screen and (max-width: 600px) {
  .price-table td.price a {
    text-transform: uppercase;
    font-size: 7px;
    padding: 10px 5px;
    font-weight: 800;
  }
  .price-table tr td {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    padding: 10px 5px;
    font-size: 10px;
    text-align: left;
  }
}

</style>
