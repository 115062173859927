<template>
    <div class="container-banner background-banner">
    <v-row style="margin: 0">
      <v-col cols="12" md="6" sm="12">
          <div class="container-main-banner">
            <div class="container-banner-text">
              <h3 class="titulo-banner fadein">Revolucione a gestão do seu Loteamento.</h3>
              <h3 class="first-title fadein">Maximize seus lucros com o sistema para Incorporadores da Obra à Carteira de Recebíveis. <span class="scae-title" style="color: white;">SCAE.</span></h3>
            </div>
            
             
          </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="container-main-banner fadein">
          <img src="../../assets/images/newImages/dashboard-site-banner.svg" class="img-banner" title="Sistema para a gestão de loteamento" alt="Sistema otimizado para loteamento"/>
          <a target="_blank" class="rn-button-style--2 fadein" title="Sistema para a gestão de loteamento"  href="https://api.whatsapp.com/send?phone=5519999600500&text=Olá! Vim pelo site e quero mais informações.">Fale com o consultor<i class='fa fa-headset icons-buttons-banner'></i></a>
          <button class="btn-demo fadein" title="Sistema para a gestão de loteamento" href="#formulario" @click="scrollToForm">Demonstração Gratuita<span class="mdi mdi-laptop icons-buttons-banner"></span></button>
        </div>
      </v-col>      
    </v-row>
    <v-row>
     </v-row>
    </div> 
</template> 
<!--<template>
  <v-container>
    <v-row>  
      <v-col cols="12">
        <v-row style="margin: 0">
          <v-col cols="12" md="6" class="inner" style="margin-top:5%">
            <h1  class="heading-title">Sistema para gestão de loteamentos.
              Simples, do inicio da obra a gestão do contrato de compra e venda.
            </h1>
              <a target="_blank" class="rn-button-style--2" title="Sistema para a gestão de loteamento"  href="https://api.whatsapp.com/send?phone=5521995343298&text=Olá! Vim pelo site e quero mais informações.">Fale com o consultor</a>
           </v-col>  

          <v-col cols="12" md="6" class="inner">
            <center>
              <img slot="logo" class="logo" src="../../assets/images/logo/imagem-banner.png" title="Sistema para a gestão de loteamento" alt="Sistema otimizado para loteamento" />
              <slot name="heading-title"></slot>
              <slot name="description"></slot>
            </center>
          </v-col>
        </v-row> 
      </v-col>  
    </v-row> 
  </v-container>
</template> -->
<script>
export default {
  methods: {
    scrollToForm() {
      this.$emit('scroll-to-form');
    }
  }
};
</script>
